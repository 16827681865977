const xyzCpa = {};
xyzCpa.dedup_cookie = 'xyz_cpa_dedup';

/**
 * Sends pageType to Advcake and CityAds
 *
 * Runs on window load
 *
 * @return {undefined}
 */
xyzCpa.init = function () {
	let intervalCount = 0;
	const maxIntervalCount = 10;
	let mainInterval = setInterval(() => {
		intervalCount++;
		document.querySelectorAll('.js-form-proccess').forEach(function (form) {
			form.dataset.successCallback = 'xyz__success_form';
		});

		if (intervalCount >= maxIntervalCount) {
			clearInterval(mainInterval);
		}
	})
	//Advcake
	if (window.location.pathname === '/') { // главная
		window.advcake_data = window.advcake_data || [];
		window.advcake_data.push({
			pageType: 1
		});
	} else { // остальные
		window.advcake_data = window.advcake_data || [];
		window.advcake_data.push({
			pageType: 2
		});
	}

	//CityAds код D
	let script = document.createElement("script");
	script.id = 'xcntmyAsync';
	script.type = 'text/javascript';
	script.innerHTML = `
		(function(d){
		var xscr = d.createElement( 'script' );
		xscr.async = 1;
		xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
		var x = d.getElementById( 'xcntmyAsync' );
		x.parentNode.insertBefore( xscr, x ); })(document);`;
	document.head.appendChild(script);

	window.xyzCpa.getPageInfo();
	window.xyzCpa.trackCpa();
	setTimeout(window.xyzCpa.checkPayChannel, 1000);


};

xyzCpa.xyz__success_form = function (form) {
	if (!form) return;
	if (form instanceof jQuery) {
		form = form.get(0);
	}
	let tildaRequestId = form.tildaTranId;
	const tildaLead = {};
	const inputs = Array.from(form.elements);

	inputs.forEach(input => {
		if (input.type === 'radio' && input.checked) {
			tildaLead[input.name] = input.value;
		} else if (input.type !== 'radio') {
			tildaLead[input.name] = input.value;
		}
	});

	const { name, phone, email, rate, course_name } = tildaLead;



	window.xyzCpa.sendPostback(tildaRequestId, window.xyzCpa?.pageInfo?.regions?.ru?.price || 0, tildaLead);

}


xyzCpa.getPageInfo = function () {

	function getCourseInfo(platformCourses) {
		const platformCoursesObj = JSON.parse(platformCourses);
		const pageUrl = window.location.origin + window.location.pathname;
		const courseInfo = platformCoursesObj.find(course => course.infoUrl === pageUrl.replace(/\/$/, ''));
		if (courseInfo) {
			xyzCpa.pageInfo = courseInfo;
		} else {
			xyzCpa.pageInfo = null;
		}
	}

	const platformCourses = localStorage.getItem('platformCourses');
	if (platformCourses) {
		getCourseInfo(platformCourses);
	} else {
		const apiEndpoint = "https://learn.school-xyz.com/api/public/v1/courses/";
		fetch(apiEndpoint)
			.then(response => response.json())
			.then(data => {
				// Сохраняем полученные данные в localStorage
				localStorage.setItem('platformCourses', JSON.stringify(data));

				// И вызываем функцию для поиска нужного курса
				getCourseInfo(JSON.stringify(data));
			})
			.catch(error => {
				console.error('Ошибка при получении данных с API:', error);
				xyzCpa.pageInfo = null; // Обрабатываем ошибку, если запрос не удался
			});
	}

}


/**
 * Returns the value of the cookie with the given name.
 * @param {string} name
 * @return {string|undefined}
 */
xyzCpa.getCookie = function (name) {
	try {
		let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	} catch (error) {
		console.error('Error while getting cookie:', error);
		return undefined;
	}

}

/**
 * Sets a cookie with the given name, value and options.
 * @param {string} name
 * @param {string} value
 * @param {object} [options] - Optional settings for the cookie.
 * @param {string} [options.path=/] - The path on the server where the cookie will be available.
 * @param {number|Date} [options.expires] - The number of days until the cookie expires or a Date object.
 */
xyzCpa.setCookie = function (name, value, options = {}) {
	// Базовые параметры cookie
	options = {
		path: '/',  // По умолчанию путь — корень сайта
		// Применяются пользовательские опции, если они есть
		...options
	};

	// Если указана продолжительность жизни (в днях), вычисляем дату истечения
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	} else if (typeof options.expires === 'number') {
		let date = new Date();
		date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000); // в миллисекундах
		options.expires = date.toUTCString();
	}

	// Формируем строку cookie
	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	// Добавляем опции к строке cookie
	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	// Устанавливаем cookie
	document.cookie = updatedCookie;
}

/**
 * Трекинг CPA-кликов.
 *
 * Если URL содержит параметры utm_medium=cpa и utm_source, то сохраняет их
 * в cookie {xyzCpa.dedup_cookie} на 90 дней.
 */
xyzCpa.trackCpa = function () {
	const currentUrl = new URL(location.href);
	if (currentUrl.searchParams.has('utm_medium') && currentUrl.searchParams.get('utm_medium') == 'cpa') {
		if (currentUrl.searchParams.has('utm_source')) {
			xyzCpa.setCookie(xyzCpa.dedup_cookie, currentUrl.searchParams.toString(), { expires: 90 });
		}
	}
	if (currentUrl.searchParams.has('utm_medium') && currentUrl.searchParams.get('utm_medium') == 'cpc') {
		xyzCpa.setCookie(xyzCpa.dedup_cookie, '', { expires: -1 });
	}
}

xyzCpa.checkPayChannel = function () {
	const paidMediums = ['cpa', 'cpc'];
	const cpaMediums = ['cpa'];
	const currentUrl = new URL(location.href); // текущая страница
	const currentDedupCookie = xyzCpa.getCookie(xyzCpa.dedup_cookie); // получаем куку


	if (currentDedupCookie &&
		currentUrl.searchParams.has('utm_medium') &&
		!paidMediums.includes(currentUrl.searchParams.get('utm_medium'))) {
		const parsedDedupCookie = new URLSearchParams(currentDedupCookie);
		if (parsedDedupCookie.has('utm_medium') &&
			parsedDedupCookie.get('utm_medium') == 'cpa') {
			let utmString = '';
			for (const [key, value] of parsedDedupCookie) {
				if (key.startsWith('utm_')) {
					utmString += `${key}=${value}|||`;
				}
			}
			xyzCpa.setCookie('TILDAUTM', utmString.slice(0, -3), { expires: 30 });
		}
	}
}

/**
 * Возвращает текущую дату и время в формате 'YYYY-MM-DD HH:MM:SS'.
 *
 * @return {string} текущая дата и время
 */
xyzCpa.getCurrentDateTime = function () {

	const now = new Date();

	const [year, month, day, hours, minutes, seconds] = [
		now.getFullYear(),
		String(now.getMonth() + 1).padStart(2, '0'),
		String(now.getDate()).padStart(2, '0'),
		String(now.getHours()).padStart(2, '0'),
		String(now.getMinutes()).padStart(2, '0'),
		String(now.getSeconds()).padStart(2, '0')
	];

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 2022-05-11 12:12:12

}

/**
 * Отправляет постбэк CPA-сети.
 *
 * Если в cookie {xyzCpa.dedup_cookie} содержится utm_medium=cpa, то
 * отправляет постбэк CPA-сети, указанной в utm_source.
 *
 * @param {number} tildaRequestId - ID заявки в Tilda
 * @param {number} price - сумма заказа
 * @param {object} leadFormData - данные формы
 */
xyzCpa.sendPostback = function (tildaRequestId, price, leadFormData) {
	const dedupValue = xyzCpa.getCookie(xyzCpa.dedup_cookie);

	if (!dedupValue) {
		return;
	}

	const dedupParams = new URLSearchParams(dedupValue);
	let cpaPayload = {};
	let postbackUrl = '';
	xyzCpa.postbackPromises = [];
	switch (dedupParams.get('utm_source')) {
		case 'advcake':
			cpaPayload = {
				id: tildaRequestId || 0,
				totalPrice: price || 0,
				clientType: 'new',
				trackid: xyzCpa.getCookie('advcake_track_id'),
				url: encodeURIComponent(xyzCpa.getCookie('advcake_track_url')),
				email: leadFormData?.email || 'no@email.com',
				leadName: leadFormData?.name || 'xyz_lead',
				dateCreate: xyzCpa.getCurrentDateTime(),
				status: 1
			}
			postbackUrl = 'https://api.ekacvda.com/postback/schoolxyzcom';
			xyzCpa.postbackPromises.push(
				fetch(postbackUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(cpaPayload),
					mode: 'no-cors',
				})
			);
			break;

		case 'cityads':
			cpaPayload = {
				click_id: dedupParams.get('click_id') || 0,
				order_id: tildaRequestId || 0,
				currency: 'RUB',
				status: 'new',
				customer_type: dedupParams.get('utm_term') || 'new',
				date: Date.now()
			};
			postbackUrl = `https://postback.cityads.com/service/postback?${new URLSearchParams(cpaPayload).toString()}`;
			xyzCpa.postbackPromises.push(
				fetch(postbackUrl, {
					method: 'GET',
					mode: 'no-cors'
				})
			);
			break;

		case 'gdeslon':
			cpaPayload = {
				order_id: tildaRequestId || 0,
				token: dedupParams.get('utm_content') || '',
				order_total: price || 0,
				commission: Math.round(price * 0.26) || 0,
			}
			postbackUrl = `https://gdeslon.ru/api/v1/postbacks/?${new URLSearchParams(cpaPayload).toString()}`;
			xyzCpa.postbackPromises.push(
				fetch(postbackUrl, {
					method: 'GET',
					//headers: {
					//	'Content-Type': 'application/json'
					//},
					//body: JSON.stringify(cpaPayload),
					mode: 'no-cors'
				})
			);
			break;

		case 'cpaex':
			cpaPayload = {
				click_id: dedupParams.get('click_id'),
				goal_id: 5316,
				track_id: tildaRequestId || 0,
			}
			postbackUrl = `https://go.cpaex.ru/track/goal-by-click-id?${new URLSearchParams(cpaPayload).toString()}`;
			xyzCpa.postbackPromises.push(
				fetch(postbackUrl, {
					method: 'GET',
					mode: 'no-cors'
				})
			);

		default:
			break;
	}

}

window.xyzCpa = xyzCpa;
window.xyz__success_form = xyzCpa.xyz__success_form;

if (document.readyState === 'loading') {
	// Если документ еще загружается, ждем события DOMContentLoaded
	document.addEventListener('DOMContentLoaded', window.xyzCpa.init);
} else {
	// Если документ уже загружен, сразу выполняем
	window.xyzCpa.init();
}


